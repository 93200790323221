// extracted by mini-css-extract-plugin
export var column = "ProviderBrochuresIndexSidebar__column__tt24C";
export var flex = "ProviderBrochuresIndexSidebar__flex__gXWzx";
export var flexColumn = "ProviderBrochuresIndexSidebar__flexColumn__lM52F";
export var gap1 = "ProviderBrochuresIndexSidebar__gap1__A2Mg3";
export var gap2 = "ProviderBrochuresIndexSidebar__gap2__EAqKu";
export var gap3 = "ProviderBrochuresIndexSidebar__gap3___uQCV";
export var gap4 = "ProviderBrochuresIndexSidebar__gap4__MLmBl";
export var gap5 = "ProviderBrochuresIndexSidebar__gap5__IKyZ2";
export var popularBrochures = "ProviderBrochuresIndexSidebar__popularBrochures__ywuY4";
export var providerBrochureLink = "ProviderBrochuresIndexSidebar__providerBrochureLink__HNCVr";
export var recentBrochures = "ProviderBrochuresIndexSidebar__recentBrochures__vZCIj";
export var row = "ProviderBrochuresIndexSidebar__row__fy7pU";
export var sidebar = "ProviderBrochuresIndexSidebar__sidebar__tWqGY";